<template>
  <div id="explorer-upgrade">
    <!-- TITLE -->
    <div
      v-if="planOptions.length || minuteAddOnOptions.length"
      ref="title"
      class="explorer-upgrade-title"
      tabindex="0"
      v-html="titleHtml"
    />
    <h2
      v-else
      ref="title"
      class="explorer-upgrade-title"
      tabindex="0"
    >
      No Upgrade or Purchase options available.
    </h2>

    <!-- ERRORS -->
    <FormErrors />

    <!-- ORDER OPTIONS -->
    <div v-if="!optionSelected && !success">
      <!-- SECTION - UPGRADE PLAN -->
      <h2
        class="profile-heading"
      >
        CHANGE PLAN
      </h2>
      <!-- TODO: Remove `|| true` when we re-enable in-app subscriptions and upgrades. -->
      <p
        v-if="!planOptions.length || true"
      >
        To change your plan, our Customer Care team is available daily from 6:00 AM to 6:00 PM Pacific Time at
        <a href="tel:+18008351934" aria-label="1 800 835 1934">1-800-835-1934</a>.
        For additional contact options, visit
        <a href="https://aira.io/contact-us/">Contact Us</a>.
      </p>
      <!-- TODO: Uncomment this when we re-enable in-app subscriptions and upgrades.
      <FormPlanButtonsSelect
        v-if="planOptions.length"
        :options="planOptions"
        :supports-hardware="false"
        bind-id="plan"
        aria-label="plan"
      />
      -->
      <!-- SECTION - PURCHASE MINUTES -->
      <h2
        v-if="minuteAddOnOptions.length"
        class="profile-heading"
      >
        BUY MINUTES
      </h2>
      <FormPlanButtonsSelect
        v-if="minuteAddOnOptions.length"
        :options="minuteAddOnOptions"
        :supports-hardware="false"
        bind-id="minuteAddOn"
        aria-label="minuteAddOn"
      />
    </div>

    <!-- ORDER SUMMARY & CONFIRMATION -->
    <div v-if="optionSelected && !success">
      <!-- PAYMENT METHOD -->
      <h2 class="profile-heading">
        PAYMENT METHOD
      </h2>
      <SignupSummaryInfo
        class="payment-method"
        variant="payment"
        route="/mobile/profile/edit/billing"
        link-text="Change"
      />
      <!-- SUMMARY -->
      <SignupOrderSummary />

      <!-- SUBMIT BUTTON -->
      <b-btn
        :block="true"
        :disabled="btnDisabled"
        variant="primary"
        @click="clickSubmit"
      >
        <span v-if="!btnDisabled">Complete Purchase</span>
        <span v-else>
          <b-spinner label="Order in Progress" />
        </span>
      </b-btn>

      <SignupAgreementClause
        :variant="type === 'primary' ? 'explorerUpgrade' : 'minutePurchase'"
        :links="true"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FormErrors from '../Form/FormErrors.vue';
import FormPlanButtonsSelect from '../Form/FormPlanButtonsSelect.vue';
import SignupSummaryInfo from '../Signup/SignupComponents/SignupSummaryInfo.vue';
import SignupOrderSummary from '../Signup/SignupComponents/SignupOrderSummary.vue';
import SignupAgreementClause from '../Signup/SignupComponents/SignupAgreementClause.vue';

export default {
  name: 'ExplorerUpgrade',
  components: {
    FormErrors,
    FormPlanButtonsSelect,
    SignupSummaryInfo,
    SignupOrderSummary,
    SignupAgreementClause,
  },
  data() {
    return {
      btnDisabled: false,
      submitting: '',
      success: false,
    };
  },
  computed: {
    ...mapGetters([
      'plan',
      'planCode',
      'planOptions',
      'minuteAddOn',
      'minuteAddOnOptions',
    ]),
    optionSelected() {
      return (this.planCode || this.minuteAddOn);
    },
    type() {
      if (this.planCode) {
        return 'primary';
      } else if (this.minuteAddOn) {
        return 'adjustment';
      } else {
        return '';
      }
    },
    titleHtml() {
      if (this.success) {
        let results = `<h1
          class="explorer-upgrade-title">
          Payment Successful
        </h1>`;
        switch (this.type) {
          case 'primary':
            results += '<p>Your new plan\'s minutes will be added to your account shortly!</p>';
            return results;
          case 'adjustment':
            results += '<p>Your new minutes will be added to your account shortly!</p>';
            return results;
          default:
            return results;
        }
      } else {
        return `<h1
          class="explorer-upgrade-title">
          ${this.optionSelected ? 'Review your purchase summary' : 'Running low on minutes?'}
        </h1>`;
      }
    },
  },
  watch: {
    planCode() {
      this.$store.dispatch('setPlan');
    },
    plan(val) {
      if (val) {
        this.$store.dispatch('getExplorerUpgradeOrderPreview');
        this.$refs.title.focus();
      }
    },
    minuteAddOn(val) {
      if (val) {
        this.$store.dispatch('getAddOnOrderPreview');
        this.$refs.title.focus();
      }
    },
  },
  methods: {
    async clickSubmit() {
      this.btnDisabled = true;
      try {
        let response;

        if (this.type === 'primary') response = await this.$store.dispatch('doExplorerUpgrade');
        if (this.type === 'adjustment') response = await this.$store.dispatch('doAddOnPurchase');

        if (response) {
          this.$cookies.remove('ssoToken');
          this.success = true;
          this.$refs.title.focus();
          const timer = setInterval(() => {
            window.scrollTo(0, 0);
            this.$refs.title.focus();
            clearInterval(timer);
          }, 3000);

          // try to send the message to iOS
          if (window.webkit) {
            if (this.type === 'primary') window.webkit.messageHandlers.appInterface.postMessage('EXPLORER_UPGRADE_SUCCESS');
            if (this.type === 'adjustment') window.webkit.messageHandlers.appInterface.postMessage('MINUTE_PURCHASE_SUCCESS');
          } else if (window.appInterface) { // android custom binding
            if (this.type === 'primary') window.appInterface.postMessage('EXPLORER_UPGRADE_SUCCESS');
            if (this.type === 'adjustment') window.appInterface.postMessage('MINUTE_PURCHASE_SUCCESS');
          }
        } else {
          this.btnDisabled = true;
        }

        // throw success event
      } catch (error) {
        console.error(error);
        error(error.errorMessage);

        this.btnDisabled = false;
      }
    },
  },
};
</script>

 <style lang="scss">
 @import '../../styles/theme/_mobile.scss';
 #explorer-upgrade {
  color: $body-color;
  padding: 0 15px;
  .explorer-upgrade-title {
    padding: 20px 0;
    outline: 0 !important;
    font-size: larger;
  }
  .explorer-upgrade-container {
    height: 100%;
    text-align: left;
    padding-top: 25px;
  }
  .profile-heading {
    text-align: left;
  }
  .payment-method {
    display: inline-block;
    width: 100%;
  }
  button {
    border-radius: 3px;
    font-weight: 600;
  }
  .signup-buttons {
    margin-top: 25px;
  }
  p {
    margin-bottom: .5rem;
  }
 }
 </style>

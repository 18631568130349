<template>
  <div
    :id="bindId"
    v-bind="$attrs"
  >
    <FormPlanButtonsSelectOption
      v-for="plan in options"
      :key="plan.planCode || plan.code"
      :plan="plan"
      :plan-name="plan.planName || plan.name"
      :plan-code="plan.planCode || plan.code"
      :minutes="plan.minutes"
      :cost="plan.price"
      :type="plan.productType"
      :supports-hardware="supportsHardware"
      :hardware="hardwareOptions(plan.hardwarePlans) > 0"
      :next-step="hardwareOptions(plan.hardwarePlans) ? 2 : 5"
    />
  </div>
</template>

<script>
import FormPlanButtonsSelectOption from './FormPlanButtonsSelectOption.vue';

export default {
  name: 'FormPlanButtonsSelect',
  components: {
    FormPlanButtonsSelectOption,
  },
  props: {
    bindId: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    supportsHardware: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    hardwareOptions(hardwarePlans) {
      return hardwarePlans ? hardwarePlans.length : false;
    },
  },
};
</script>

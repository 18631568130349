<template>
  <b-button
    v-if="type === 'primary'"
    :aria-label="`${planName} Plan, ${minutes} minutes, ${price} per month, ${hardwareReading || ''}.`"
    class="buttons-select-option-button"
    size="lg"
    variant="outline-primary"
    @click="planClicked()"
  >
    <span
      aria-hidden="true"
      class="buttons-select-option-content-title"
    >
      {{ planName }}
    </span>
    <span
      aria-hidden="true"
      class="buttons-select-option-content-main float-left"
    >
      {{ minutes }} min
    </span>
    <div
      v-if="supportsHardware"
      aria-hidden="true"
      class="buttons-select-option-content-right float-right"
    >
      <span
        aria-hidden="true"
        class="buttons-select-option-content-right-main"
      >
        {{ price }}
        <small><small>/mo</small></small></span>
      <span
        aria-hidden="true"
        class="buttons-select-option-content-right-sub"
      >
        {{ hardwareBlurp }}
      </span>
    </div>
    <div
      v-else
      aria-hidden="true"
      class="buttons-select-option-content-right float-right"
    >
      <span
        aria-hidden="true"
        class="buttons-select-option-content-right-main-only"
      >
        {{ price }}
        <small><small>/mo</small></small></span>
    </div>
  </b-button>
  <b-button
    v-else-if="type === 'adjustment'"
    :aria-label="`${planName}, ${minutes} minutes, ${price}, never expires.`"
    class="buttons-select-option-button"
    size="lg"
    variant="outline-primary"
    @click="planClicked()"
  >
    <span
      aria-hidden="true"
      class="buttons-select-option-content-title"
    >
      Purchased
    </span>
    <span
      aria-hidden="true"
      class="buttons-select-option-content-main float-left"
    >
      {{ minutes }} min
    </span>
    <div
      aria-hidden="true"
      class="buttons-select-option-content-right float-right"
    >
      <span
        aria-hidden="true"
        class="buttons-select-option-content-right-main"
      >
        {{ price }}
      </span>
      <span
        aria-hidden="true"
        class="buttons-select-option-content-right-sub"
      >
        Never Expires
      </span>
    </div>
  </b-button>
</template>

<script>
export default {
  name: 'FormPlanButtonsSelectOption',
  props: {
    plan: {
      type: Object,
      required: true,
    },
    planName: {
      type: String,
      required: true,
    },
    planCode: {
      type: String,
      required: true,
    },
    minutes: {
      type: Number,
      required: true,
    },
    cost: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    supportsHardware: {
      type: Boolean,
      required: false,
      default: true,
    },
    hardware: {
      type: Boolean,
      required: true,
    },
    nextStep: {
      type: Number,
      required: true,
    },
  },
  computed: {
    hardwareBlurp() {
      if (!this.supportsHardware) return '';
      return this.hardware ? `${this.hardwarePrice}/mo with Horizon` : '';
    },
    hardwareReading() {
      if (!this.supportsHardware) return '';
      return this.hardware ? `${this.hardwarePrice} per month with Horizon` : '';
    },
    price() {
      if (!this.plan.currency) return '';
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: this.plan.currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
      }).format(this.cost / 100);
    },
    hardwarePrice() {
      if (!this.hardware || !this.plan.hardwarePlans || !this.plan.hardwarePlans.length) return 0;
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: this.plan.currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
      }).format((this.cost + this.plan.hardwarePlans[0].price) / 100);
    },
  },
  methods: {
    planClicked() {
      if (this.type === 'primary') {
        this.$store.commit('SET_PLAN_CODE', this.planCode);
        this.$store.commit('SET_STEP', this.nextStep);
      } else if (this.type === 'adjustment') {
        this.$store.commit('SET_MINUTE_ADD_ON', this.plan);
      }
    },
  },
};
</script>
